import { Link } from "react-router-dom";
import * as Icon from 'react-feather';


export const Services = () => {

  return (
    <>
      <div className="services-area-two pt-80 pb-50 bg-f9f6f6">
        <div className="container">
          <div className="section-title">
            <h2>Зачем нужен сайт?</h2>
            <div className="bar"></div>
            <p>Ваш веб-сайт - это цифровой визитная карточка вашего бизнеса, и первое впечатление может сделать чудеса. Наши эксперты в веб-разработке и дизайне работают над тем, чтобы создать функциональные, креативные и пользовательские веб-сайты, которые не только привлекают внимание, но и конвертируют посетителей в клиентов. Мы предлагаем полный цикл услуг, начиная от концептуализации и дизайна и заканчивая разработкой и оптимизацией для максимальной производительности.</p>
          </div>

          <div className="row justify-content-center">
            <div className="col-lg-4 col-sm-6">
              <div className="single-services-box">
                <div className="icon">
                  <Icon.Settings />
                </div>
                <h3>
                  <Link to="/service-details">
                    Веб-дизайн
                  </Link>
                </h3>
                <p>Разработка уникального и привлекательного дизайна, который отражает вашу марку и ценности.</p>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6">
              <div className="single-services-box">
                <div className="icon">
                  <Icon.Code />
                </div>
                <h3>
                  <Link to="/service-details">
                    Веб-разработка
                  </Link>
                </h3>
                <p>Создание функциональных и масштабируемых веб-сайтов с использованием современных технологий.</p>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6">
              <div className="single-services-box">
                <div className="icon">
                  <Icon.Tablet />
                </div>
                <h3>
                  <Link to="/service-details">
                    Мобильная адаптация
                  </Link>
                </h3>
                <p>Оптимизация сайта для мобильных устройств, обеспечивая легкий доступ для всех пользователей.</p>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6">
              <div className="single-services-box">
                <div className="icon bg-c679e3">
                  <Icon.Grid />
                </div>
                <h3>
                  <Link to="/service-details">
                    Электронная коммерция
                  </Link>
                </h3>
                <p>Разработка интернет-магазинов с удобным пользовательским интерфейсом и безопасными платежными системами.</p>
              </div>
            </div>


            <div className="col-lg-4 col-sm-6">
              <div className="single-services-box">
                <div className="icon bg-eb6b3d">
                  <Icon.Search />
                </div>
                <h3>
                  <Link to="/service-details">
                    Управление контентом
                  </Link>
                </h3>
                <p>Создание систем управления контентом (CMS), которые позволяют вам легко обновлять информацию на сайте.</p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="single-services-box">
                <div className="icon bg-c679e3">
                  <Icon.Crosshair />
                </div>
                <h3>
                  <Link to="/service-details">
                    Аналитика и оптимизация
                  </Link>
                </h3>
                <p>Мониторинг производительности и оптимизация сайта для достижения максимальных результатов.</p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="single-services-box">
                <div className="icon bg-eb6b3d">
                  <Icon.Users />
                </div>
                <h3>
                  <Link to="/service-details">
                    SEO-оптимизация
                  </Link>
                </h3>
                <p>Оптимизация сайта для поисковых систем, чтобы повысить его видимость и ранжирование.</p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="single-services-box">
                <div className="icon bg-c679e3">
                  <Icon.Lock />
                </div>
                <h3>
                  <Link to="/service-details">
                    Безопасность веб-сайта
                  </Link>
                </h3>
                <p>Защита сайта от угроз и вредоносных атак для обеспечения безопасности данных.</p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="single-services-box">
                <div className="icon bg-eb6b3d">
                  <Icon.Smile />
                </div>
                <h3>
                  <Link to="/service-details">
                    Поддержка и обслуживание
                  </Link>
                </h3>
                <p>Регулярное обновление и техническая поддержка, чтобы ваш сайт всегда работал без сбоев.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

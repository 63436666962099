import { Helmet } from 'react-helmet';
import Navbar from "../components/Navbar";
import PageBanner from '../components/PageBanner';
import { Services } from '../components/seo-page/services';
import { Prices } from "../components/seo-page/prices";
import { FAQ } from '../components/Faq';
import { questions, answers } from '../components/seo-page/questions';


const SeoDetails = () => {
  return (
    <>
      <Helmet>
        <title>SEO продвижение</title>
        <meta name="description" content="SEO помогает бизнесу повысить видимость в интернете, привлечь органический трафик и увеличить число потенциальных клиентов, что способствует росту прибыли и конкурентоспособности компании." />
      </Helmet>
      < Navbar />
      < PageBanner pageTitle="SEO-оптимизация" />
      < Services />
      < Prices />
      < FAQ questions={questions} answers={answers} />
    </>
  )
}

export default SeoDetails
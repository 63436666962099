import { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";


const INITIAL_STATE = {
    name: "",
    email: "",
    phone: "",
    subject: "",
    text: "",
};

const ContactForm = () => {

    const [contact, setContact] = useState(INITIAL_STATE);
    const [isChecked, setIsChecked] = useState(false);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");

    const handleCheckboxChange = (e) => {
        setIsChecked(e.target.checked);
        setError("");
    };


    const handleChange = (e) => {
        const { name, value } = e.target;
        setContact((prevState) => ({ ...prevState, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (isChecked) {
            try {
                const url = '/sendEmail.php';
                const { name, email, phone, subject, text, contactMethod } = contact;
                const payload = { name, email, phone, subject, text, contactMethod };

                const response = await axios.post(url, payload);
                console.log(response.data);

                setContact(INITIAL_STATE);
                setSuccess("Ваше сообщение было успешно отправлено и скоро мы ответим вам")
            } catch (error) {
                console.log(error);
            }
        } else {
            setError("Пожалуйста, согласитесь с правилами и политикой приватности.");
        }

    };


    return (
        <>
            <div className="contact-area ptb-80">
                <div className="container">
                    <div className="section-title">
                        <h2>Свяжитесь с нами</h2>
                        <div className="bar"></div>
                        <p>Мы Будем Рады Вам Помочь!</p>
                    </div>

                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <img src="/images/undraw.svg" alt="undraw" />
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                name="name"
                                                placeholder="Как вас зовут?"
                                                className="form-control"
                                                value={contact.name}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                name="email"
                                                placeholder="Ваш email"
                                                className="form-control"
                                                value={contact.email}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                name="phone"
                                                placeholder="Ваш номер телефона"
                                                className="form-control"
                                                value={contact.phone}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                name="subject"
                                                placeholder="Тема"
                                                className="form-control"
                                                value={contact.subject}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <textarea
                                                name="text"
                                                cols="30"
                                                rows="5"
                                                placeholder="Пишите Ваше сообщение..."
                                                className="form-control"
                                                value={contact.text}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>

                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked={isChecked}
                                                onChange={handleCheckboxChange} />
                                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                                Нажимая это, вы соглашаетесь с нашими <Link to="/term-condition">правилами</Link> и <Link to="/privacy-policy">политикой приватности</Link>.
                                            </label>
                                        </div>
                                    </div>
                                    {error && <p className="text-danger">{error}</p>}
                                    <div className="col-lg-12 col-sm-12">
                                        <button type="submit" className="btn btn-primary">Отправить сообщение</button>
                                    </div>
                                    {success && <p className="text-success">{success}</p>}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ContactForm;
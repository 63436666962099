import { Link } from "react-router-dom";
import * as Icon from 'react-feather';
import { useModal } from '../modal/ModalContext';

export const Prices = () => {

  const { handleShow } = useModal();




  return (
    <>
      <div className="pricing-area pb-50">
        <div className="container">
          <div className="section-title st-fs-28 mt-3">
            <span className="sub-title">Цены на ведение рекламы</span>
            <div className="bar"></div>
            <p> Мы предлагаем гибкие и конкурентоспособные цены на наши услуги. Выберите наиболее подходящий пакет для ваших целей и начните достижение успеха в интернете уже сегодня.</p>
          </div>

          <div className="tab pricing-tab bg-color">

            <div className="tab_content">
              <div id="tab1" className="tabs_item">
                <div className="row justify-content-center">

                  <div className="col-lg-4 col-md-6 col-sm-6">
                    <div className="pricing-box">
                      <div className="pricing-header">
                        <h3>Контекстная реклама для сайтов услуг</h3>
                        <p>Получите больше заявок и продаж с наименьшими инвестициями в интернет рекламу</p>
                      </div>

                      <div className="price">
                        от 30 000 руб. <span>/месяц</span>
                      </div>

                      <div className="buy-btn">
                        <button className="btn btn-primary" onClick={() => handleShow("Реклама для сайтов услуг")}>
                          Выбрать
                        </button>
                      </div>

                      <ul className="pricing-features">
                        <li><Icon.Check /> Сквозная аналитика</li>
                        <li><Icon.Check /> Внедрение CRM</li>
                        <li><Icon.Check /> Яндекс Директ (Поиск, РСЯ, ретаргетинг)</li>
                        <li><Icon.Check /> Таргетированная реклама</li>
                        <li><Icon.Check /> А/Б тестирование</li>
                        <li><Icon.Check /> Персональный менеджер</li>
                      </ul>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-6 col-sm-6">
                    <div className="pricing-box">
                      <div className="pricing-header">
                        <h3>Контекстная реклама для интернет-магазинов</h3>
                        <p>Выполним ваш KPI в рамках доли расходов, выручке, количестве продаж</p>
                      </div>

                      <div className="price">
                        от 70 000 руб. <span>/месяц</span>
                      </div>

                      <div className="buy-btn">
                        <button className="btn btn-primary" onClick={() => handleShow("Контекстная реклама для интернет-магазинов")}>
                          Выбрать
                        </button>
                      </div>

                      <ul className="pricing-features">
                        <li><Icon.Check /> Сквозная аналитика</li>
                        <li><Icon.Check /> Внедрение CRM</li>
                        <li><Icon.Check /> Яндекс Директ (Поиск, РСЯ, ретаргетинг)</li>
                        <li><Icon.Check /> Таргетированная реклама</li>
                        <li><Icon.Check /> Веб-аналитика и тестирование гипотез</li>
                        <li><Icon.Check /> А/Б тестирование</li>
                        <li><Icon.Check /> Персональный менеджер</li>
                        <li><Icon.Check /> Продвижение на маркетплейсах</li>
                        <li><Icon.Check /> SEO оптимизация</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div id="tab2" className="tabs_item">
                <div className="row justify-content-center">
                  <div className="col-lg-4 col-md-6 col-sm-6">
                    <div className="pricing-box">
                      <div className="pricing-header">
                        <h3>Стартовый пакет</h3>
                        <p>Начните улучшение видимости вашего сайта с нашего стартового пакета SEO-оптимизации</p>
                      </div>

                      <div className="price">
                        от 300 000 руб. <span>/год</span>
                      </div>

                      <div className="buy-btn">
                        <Link to="/contact/" className="btn btn-primary">
                          Выбрать
                        </Link>
                      </div>

                      <ul className="pricing-features">
                        <li><Icon.Check /> Аудит сайта</li>
                        <li><Icon.Check /> Исследование ключевых слов</li>
                        <li><Icon.Check /> Оптимизация контента</li>
                        <li><Icon.Check /> Техническая оптимизация</li>
                        <li><Icon.Check /> Создание карты сайта и robots.txt</li>
                        <li><Icon.Check /> Оптимизация мета-тегов</li>
                        <li><Icon.Check /> Отчёт и рекомендации</li>
                      </ul>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-6 col-sm-6">
                    <div className="pricing-box">
                      <div className="pricing-header">
                        <h3>Продвинутый пакет</h3>
                        <p>С нашим продвинутым пакетом SEO-оптимизации вы получите всестороннюю оптимизацию сайта</p>
                      </div>

                      <div className="price">
                        от 700 000 руб. <span> /год</span>
                      </div>

                      <div className="buy-btn">
                        <Link to="/contact/" className="btn btn-primary">
                          Выбрать
                        </Link>
                      </div>

                      <ul className="pricing-features">
                        <li><Icon.Check /> Аудит сайта и конкурентный анализ</li>
                        <li><Icon.Check /> Исследование ключевых слов</li>
                        <li><Icon.Check /> Оптимизация контента</li>
                        <li><Icon.Check /> Техническая оптимизация</li>
                        <li><Icon.Check /> Создание и оптимизация мета-тегов</li>
                        <li><Icon.Check /> Построение ссылок</li>
                        <li><Icon.Check /> Мониторинг и аналитика</li>
                        <li><Icon.Check /> Социальные сети и контент-маркетинг</li>
                        <li><Icon.Check /> Отчётность и консультации</li>
                      </ul>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-6 col-sm-6">
                    <div className="pricing-box">
                      <div className="pricing-header">
                        <h3>Премиум-пакет</h3>
                        <p>Наш премиум-пакет - это комплексное решение для максимального уровня видимости и эффективности вашего сайта</p>
                      </div>

                      <div className="price">
                        от 2 000 000 руб. <span>/год</span>
                      </div>

                      <div className="buy-btn">
                        <Link to="/contact/" className="btn btn-primary">
                          Выбрать
                        </Link>
                      </div>

                      <ul className="pricing-features">
                        <li><Icon.Check /> Аудит сайта и конкурентный анализ</li>
                        <li><Icon.Check /> Исследование ключевых слов</li>
                        <li><Icon.Check /> Оптимизация контента</li>
                        <li><Icon.Check /> Техническая оптимизация</li>
                        <li><Icon.Check /> Создание и оптимизация мета-тегов</li>
                        <li><Icon.Check /> Построение авторитетных ссылок</li>
                        <li><Icon.Check /> Мониторинг и аналитика</li>
                        <li><Icon.Check /> Социальные сети и контент-маркетинг</li>
                        <li><Icon.Check /> Оптимизация мобильных приложений</li>
                        <li><Icon.Check /> Индивидуальные консультации</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  );
}

import MainBanner from "../components/MainBanner";
import OurServices from "../components/OurServices";
import FunFactsArea from "../components/FunFactsArea";
import { Helmet } from 'react-helmet';

const MainPage = () => {
  return (
    <>
      <Helmet>
        <title>Цифровая корпорация</title>
        <meta name="description" content="Создание и продвижение сайтов. Современный UX/UI дизайн" />
      </Helmet>
      < MainBanner />
      < OurServices />
      < FunFactsArea />
    </>
  )
}

export default MainPage;
import { Link } from "react-router-dom";
import * as Icon from 'react-feather';

export const Services = () => {
  return (
    <>

      <div className="services-area-two pt-80 pb-50 bg-f9f6f6">
        <div className="container">
        <div className="section-title">
            <h2>Зачем SEO бизнесу?</h2>
            <div className="bar"></div>
            <p>SEO помогает бизнесу повысить видимость в интернете, привлечь органический трафик и увеличить число потенциальных клиентов, что способствует росту прибыли и конкурентоспособности компании.</p>
          </div>

          <div className="row justify-content-center">
            <div className="col-lg-4 col-sm-6">
              <div className="single-services-box">
                <div className="icon">
                  <Icon.Settings />
                </div>
                <h3>
                  <Link to="/service-details">
                    Комплексная SEO-оптимизация
                  </Link>
                </h3>
                <p>Анализ, разработка стратегии и реализация всесторонней оптимизации сайта.</p>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6">
              <div className="single-services-box">
                <div className="icon">
                  <Icon.ZoomIn />
                </div>
                <h3>
                  <Link to="/service-details">
                    Исследование ключевых слов
                  </Link>
                </h3>
                <p>Подбор и анализ ключевых слов для эффективной SEO-стратегии.</p>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6">
              <div className="single-services-box">
                <div className="icon">
                  <Icon.Edit3 />
                </div>
                <h3>
                  <Link to="/service-details">
                    Создание контента
                  </Link>
                </h3>
                <p>Разработка уникальных и оптимизированных текстов, статей и мультимедийного контента.</p>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6">
              <div className="single-services-box">
                <div className="icon bg-c679e3">
                  <Icon.Grid />
                </div>
                <h3>
                  <Link to="/service-details">
                    Техническая оптимизация
                  </Link>
                </h3>
                <p>Улучшение технических характеристик сайта, включая скорость загрузки и мобильную совместимость.</p>
              </div>
            </div>


            <div className="col-lg-4 col-sm-6">
              <div className="single-services-box">
                <div className="icon bg-eb6b3d">
                  <Icon.Search />
                </div>
                <h3>
                  <Link to="/service-details">
                    Аналитика и мониторинг
                  </Link>
                </h3>
                <p>Установка инструментов аналитики и отслеживание результатов для корректировки стратегии.</p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="single-services-box">
                <div className="icon bg-c679e3">
                  <Icon.Crosshair />
                </div>
                <h3>
                  <Link to="/service-details">
                    Локальный SEO
                  </Link>
                </h3>
                <p>Оптимизация для местных поисковых запросов, улучшение видимости на местных картах и каталогах.</p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="single-services-box">
                <div className="icon bg-eb6b3d">
                  <Icon.Users />
                </div>
                <h3>
                  <Link to="/service-details">
                    Социальные сигналы
                  </Link>
                </h3>
                <p>Взаимодействие с социальными сетями и использование социальных сигналов.</p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="single-services-box">
                <div className="icon bg-c679e3">
                  <Icon.TrendingUp />
                </div>
                <h3>
                  <Link to="/service-details">
                    Построение обратных ссылок
                  </Link>
                </h3>
                <p>Создание качественных бэклинков для увеличения авторитета сайта.</p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="single-services-box">
                <div className="icon bg-eb6b3d">
                  <Icon.Smile />
                </div>
                <h3>
                  <Link to="/service-details">
                    Репутационный маркетинг
                  </Link>
                </h3>
                <p>Управление онлайн-репутацией и работа с отзывами и рейтингами.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

import { Helmet } from 'react-helmet';
import { useNavigate } from "react-router-dom";

const Custom404 = () => {
	const navigate = useNavigate();
	return (
		<>
			<Helmet>
				<title>что то пошло не так...</title>
				<meta name="description" content="Запрашиваемая вами страница не существует" />
			</Helmet>
			<div className="error-area">
				<div className="d-table">
					<div className="d-table-cell">
						<div className="container">
							<div className="error-content">
								<div className="notfound-404">
									<h1>Oops!</h1>
								</div>
								<h3>404 - Страница не найдена</h3>
								<p>Возможно, страница, которую вы ищете, была удалена, ее название изменилось или она временно недоступна.</p>
								<button onClick={() => navigate(-1)} className="btn btn-primary">
									Вернуться
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Custom404
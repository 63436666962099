import PageBanner from '../components/PageBanner';

const TermCondition = () => {
    return (
        <>
            < PageBanner pageTitle="Условия использования" />

            <div className="main-text-area ptb-80">
                <div className="container">
                    <h3>Добро пожаловать на наш веб-сайт.</h3>
                    <p>Пожалуйста, ознакомьтесь с нижеследующими условиями использования, которые регулируют вашу посещаемость и использование нашего сайта. Если вы продолжаете пользоваться нашим сайтом, это означает ваше согласие с этими условиями.</p>

                    <p>Права собственности:
                        Все материалы, содержащиеся на нашем сайте, являются объектами авторского права и принадлежат Цифровой Корпорации или третьим лицам, предоставившим нам соответствующие права. Запрещено копирование, распространение, модификация или иное использование этих материалов без нашего предварительного письменного разрешения.
                    </p>
                    <p>
                        Контент пользователя:
                        При предоставлении пользовательского контента, вы гарантируете, что у вас есть право делиться этим контентом и что он не нарушает авторские права, права на конфиденциальность или другие права третьих лиц.
                    </p>
                    <p>
                        Конфиденциальность:
                        Мы обрабатываем ваши персональные данные в соответствии с нашей политикой конфиденциальности. Пожалуйста, ознакомьтесь с нашей политикой конфиденциальности, чтобы узнать подробности о том, как мы собираем, используем и защищаем вашу личную информацию.
                    </p>
                    <p>
                        Ограничение ответственности:
                        Мы не несем ответственности за любые убытки или ущерб, возникшие в результате использования нашего сайта или связанных с ним материалов. Ваше использование сайта и его содержимого осуществляется на ваш риск.
                    </p><p>
                        Изменения в условиях:
                        Мы оставляем за собой право вносить изменения в настоящие условия использования в любое время без предварительного уведомления. Рекомендуется периодически проверять наличие изменений.
                    </p><p>
                        Пользуясь нашим сайтом, вы соглашаетесь с вышеуказанными условиями использования. Если у вас возникли вопросы или требуется дополнительная информация, пожалуйста, свяжитесь с нами.
                    </p> </div>
            </div>

        </>
    )
}

export default TermCondition;
import { createContext, useContext, useState } from 'react';

const ModalContext = createContext();

export const useModal = () => {
  return useContext(ModalContext);
};

export const ModalProvider = ({ children }) => {
  const [show, setShow] = useState(false);
  const [tariff, setTariff] = useState('');

  const handleClose = () => {
    setShow(false);
    setTariff('');
    console.log(tariff)
  };

  const handleShow = (selectedTariff) => {
    setTariff(selectedTariff);
    setShow(true);
    console.log(tariff)
  };


  return (
    <ModalContext.Provider value={{ show, handleShow, handleClose, tariff }}>
      {children}
    </ModalContext.Provider>
  );
};

import { Link } from "react-router-dom";
import * as Icon from 'react-feather';

const OurServices = () => {
    return (
        <>
            <div className="ml-services-area ptb-80">
                <div className="container">
                    <div className="section-title">
                        <span className="sub-title">Что мы предлагаем</span>
                        <h2>Наши услуги</h2>
                        <div className="bar"></div>
                        <p>Инновационные стратегии, индивидуальный подход и современные технологии, чтобы достичь ваших целей и превзойти ожидания.</p>
                    </div>

                    <div className="row justify-content-center">
                        <div className="col-lg-4 col-sm-6">
                            <div className="single-services-box">
                                <div className="icon">
                                    <Icon.Code />
                                </div>
                                <h3>
                                    <Link to="/creating-websites">
                                        Разработка сайтов
                                    </Link>
                                </h3>
                                <p>Создание современных и быстрых сайтов.</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-sm-6">
                            <div className="single-services-box">
                                <div className="icon">
                                    <Icon.Figma />
                                </div>
                                <h3>
                                    UX/UI дизайн
                                </h3>
                                <p>Удобный и интуитивный дизайн.</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-sm-6">
                            <div className="single-services-box">
                                <div className="icon">
                                    <Icon.TrendingUp />
                                </div>
                                <h3>
                                    <Link to="/seo-details">
                                        SEO оптимизанция и продвижение
                                    </Link>
                                </h3>
                                <p>Продвижение вашего сайта в поисковых системах</p>
                            </div>
                        </div>

                    </div>
                </div>

                {/* Shape Images */}
                <div className="shape1">
                    <img src="/images/shape1.png" alt="shape" />
                </div>
                <div className="shape2 rotateme">
                    <img src="/images/shape2.svg" alt="shape" />
                </div>
                <div className="shape3">
                    <img src="/images/shape3.svg" alt="shape" />
                </div>
                <div className="shape4">
                    <img src="/images/shape4.svg" alt="shape" />
                </div>
                <div className="shape7">
                    <img src="/images/shape4.svg" alt="shape" />
                </div>
                <div className="shape8 rotateme">
                    <img src="/images/shape2.svg" alt="shape" />
                </div>
            </div>
        </>
    );
}

export default OurServices;
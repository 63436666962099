import { Link } from "react-router-dom";

const FunFactsArea = () => {
    return (
        <>
            <div className="funfacts-area ptb-80">
                <div className="container">
                    <div className="section-title">
                        <h2>Мы всегда стараемся понять ваши ожидания</h2>
                        <div className="bar"></div>
                        <p>Мы успешно завершили более 100 проектов для наших клиентов, поднимая их онлайн-присутствие на новый уровень.</p>
                    </div>

                    <div className="row">
                        <div className="col-lg-3 col-md-3 col-6">
                            <div className="funfact">
                                <h3>50+</h3>
                                <p>Постоянных клиентов</p>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-3 col-6">
                            <div className="funfact">
                                <h3>10</h3>
                                <p>Лет опыта</p>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-3 col-6">
                            <div className="funfact">
                                <h3>100+</h3>
                                <p>Проектов</p>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-3 col-6">
                            <div className="funfact">
                                <h3>15</h3>
                                <p>Экспертов в Команде</p>
                            </div>
                        </div>
                    </div>

                    <div className="contact-cta-box">
                        <h3>У вас есть какие-либо вопросы ?</h3>
                        <p>Не стесняйтесь обращаться к нам</p>

                        <Link to="/contact" className="btn btn-primary">
                            Связаться с нами
                        </Link>
                    </div>

                    <div className="map-bg">
                        <img src="/images/map.png" alt="map" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default FunFactsArea;
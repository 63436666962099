import React from 'react';

const MainBanner = () => {
    return (
        <>
            <div className="agency-main-banner">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="agency-banner-content">
                                <h1>Разработка современных сайтов</h1>
                                <p>Команда профессионалов, которая предоставляет комплексные услуги по созданию сайтов, разработке дизайна, продвижению и маркетингу в Интернете. Наша цель - помочь нашим клиентам достичь успеха в онлайн-бизнесе.</p>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12 mt-5">
                            <div className="agency-banner-image">
                                <img src="/images/main.svg" alt="Стартовая картинка" className="mt-5"/>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Shape Images */}
                <div className="shape2 rotateme">
                    <img src="/images/shape2.svg" alt="shape" />
                </div>
                <div className="shape8 rotateme">
                    <img src="/images/shape2.svg" alt="shape" />
                </div>
                <div className="shape3">
                    <img src="/images/shape3.svg" alt="shape" />
                </div>
                <div className="shape4">
                    <img src="/images/shape4.svg" alt="shape" />
                </div>
            </div>
        </>
    );
}

export default MainBanner;
import { Link } from "react-router-dom";
import * as Icon from 'react-feather';
import { useModal } from '../modal/ModalContext';

export const Prices = () => {

  const { handleShow } = useModal();

  return (
    <>
      <div className="pricing-area pb-50">
        <div className="container">
          <div className="section-title st-fs-28 mt-3">
            <span className="sub-title">Цены на разработку сайтов</span>
            <div className="bar"></div>
            <p> Мы предлагаем гибкие и конкурентоспособные цены на наши услуги.</p>
          </div>

          <div className="tab pricing-tab bg-color">
            <div className="tab_content">
              <div className="tabs_item">
                <div className="row justify-content-center">
                  <div className="col-lg-4 col-md-6 col-sm-6">
                    <div className="pricing-box">
                      <div className="pricing-header">
                        <h3>Лендинг</h3>
                        <p>Одностраничный сайт, созданный для привлечения внимания к конкретному продукту и стимулирования действий посетителей.</p>
                      </div>

                      <div className="price">
                        от 20 000 руб.
                      </div>

                      <div className="buy-btn">
                        <button className="btn btn-primary" onClick={() => handleShow("Разработка лендинга")}>
                          Выбрать
                        </button>
                      </div>

                      <ul className="pricing-features">
                        <li><Icon.Check /> Анализ целевой аудитории</li>
                        <li><Icon.Check /> Разработка уникального дизайна</li>
                        <li><Icon.Check /> Создание контента</li>
                        <li><Icon.Check /> Оптимизация для мобильных устройств:</li>
                        <li><Icon.Check /> Формы сбора информации</li>
                        <li><Icon.Check /> Интеграция с социальными сетями</li>
                        <li><Icon.Check /> Базовая SEO оптимизация</li>
                        <li><Icon.Check /> Аналитика и отчетность</li>
                        <li><Icon.Check /> Тестирование и оптимизация</li>
                        <li><Icon.Check /> Техническая поддержка</li>
                      </ul>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-6 col-sm-6">
                    <div className="pricing-box">
                      <div className="pricing-header">
                        <h3>Сайт-визитка</h3>
                        <p>Небольшой сайт, представляющий вашу компанию и контактные данные, идеально подходит для представления в интернете.</p>
                      </div>

                      <div className="price">
                        от 30 000 руб.
                      </div>

                      <div className="buy-btn">
                        <button className="btn btn-primary" onClick={() => handleShow("Разработка сайта визитки")}>
                          Выбрать
                        </button>
                      </div>

                      <ul className="pricing-features">
                        <li><Icon.Check /> Дизайнерский концепт</li>
                        <li><Icon.Check /> Страницы и разделы</li>
                        <li><Icon.Check /> Контент</li>
                        <li><Icon.Check /> Мобильная адаптация</li>
                        <li><Icon.Check /> Формы обратной связи</li>
                        <li><Icon.Check /> Интеграция соцсетей</li>
                        <li><Icon.Check /> Базовая SEO оптимизация</li>
                        <li><Icon.Check /> Аналитика и мониторинг</li>
                        <li><Icon.Check /> Техническая поддержка</li>
                        <li><Icon.Check /> Обновления и доработки</li>
                      </ul>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-6 col-sm-6">
                    <div className="pricing-box">
                      <div className="pricing-header">
                        <h3>Корпоративный Сайт</h3>
                        <p>Веб-платформа для более крупных компаний, обеспечивающая подробную информацию о бизнесе, услугах и истории компании.</p>
                      </div>

                      <div className="price">
                        от 50 000 руб.
                      </div>

                      <div className="buy-btn">
                        <button className="btn btn-primary" onClick={() => handleShow("Разработка корпоративного сайта")}>
                          Выбрать
                        </button>
                      </div>

                      <ul className="pricing-features">
                        <li><Icon.Check /> Стратегическое планирование</li>
                        <li><Icon.Check /> Дизайн и визуальная идентичность</li>
                        <li><Icon.Check /> Многостраничная структура</li>
                        <li><Icon.Check /> Контент-стратегия</li>
                        <li><Icon.Check /> Мобильная адаптация</li>
                        <li><Icon.Check /> Интерактивные элементы</li>
                        <li><Icon.Check /> Система управления контентом (CMS)</li>
                        <li><Icon.Check /> SEO оптимизация</li>
                        <li><Icon.Check /> Аналитика и мониторинг</li>
                        <li><Icon.Check /> Поддержка и обслуживание</li>
                      </ul>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-6 col-sm-6">
                    <div className="pricing-box">
                      <div className="pricing-header">
                        <h3>Интернет-магазин</h3>
                        <p>Онлайн-платформа для продажи товаров и услуг с удобным поиском и процессом оформления заказа.</p>
                      </div>

                      <div className="price">
                        от 150 000 руб.
                      </div>

                      <div className="buy-btn">
                        <Link to="/contact/" className="btn btn-primary">
                          Выбрать
                        </Link>
                      </div>

                      <ul className="pricing-features">
                        <li><Icon.Check /> Дизайн интерфейса</li>
                        <li><Icon.Check /> Структура и категории товаров</li>
                        <li><Icon.Check /> Корзина и оформление заказа</li>
                        <li><Icon.Check /> Интеграция системы оплаты</li>
                        <li><Icon.Check /> Управление складом</li>
                        <li><Icon.Check /> Система управления товарами</li>
                        <li><Icon.Check /> Мобильная адаптация</li>
                        <li><Icon.Check /> SEO оптимизация</li>
                        <li><Icon.Check /> Отзывы и рейтинги</li>
                        <li><Icon.Check /> Техническая поддержка</li>
                      </ul>
                    </div>
                  </div>


                  <div className="col-lg-4 col-md-6 col-sm-6">
                    <div className="pricing-box">
                      <div className="pricing-header">
                        <h3>Сайт-Каталог</h3>
                        <p>Сайт, представляющий каталог товаров или услуг без онлайн-продажи, предоставляя информацию для потенциальных клиентов.</p>
                      </div>

                      <div className="price">
                        от 150 000 руб.
                      </div>

                      <div className="buy-btn">
                        <button className="btn btn-primary" onClick={() => handleShow("Разработка сайта каталога")}>
                          Выбрать
                        </button>
                      </div>

                      <ul className="pricing-features">
                        <li><Icon.Check /> Структура каталога</li>
                        <li><Icon.Check /> Категории и фильтры</li>
                        <li><Icon.Check /> Дизайн и визуальная идентичность</li>
                        <li><Icon.Check /> Текстовый и мультимедийный контент</li>
                        <li><Icon.Check /> Мобильная адаптация</li>
                        <li><Icon.Check /> Формы обратной связи</li>
                        <li><Icon.Check /> SEO оптимизация</li>
                        <li><Icon.Check /> Интеграция с CMS</li>
                        <li><Icon.Check /> Аналитика и мониторинг</li>
                        <li><Icon.Check /> Техническая поддержка</li>
                      </ul>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-6 col-sm-6">
                    <div className="pricing-box">
                      <div className="pricing-header">
                        <h3>Сайт-Агрегатор</h3>
                        <p>Платформа, собирающая и отображающая информацию или контент с разных источников для удобного доступа пользователей.</p>
                      </div>

                      <div className="price">
                        от 500 000 руб.
                      </div>

                      <div className="buy-btn">
                        <button className="btn btn-primary" onClick={() => handleShow("Разработка сайта агрегатора")}>
                          Выбрать
                        </button>
                      </div>

                      <ul className="pricing-features">
                        <li><Icon.Check /> Интеграция и парсинг данных</li>
                        <li><Icon.Check /> Пользовательский интерфейс</li>
                        <li><Icon.Check /> Дизайн и визуальная идентичность</li>
                        <li><Icon.Check /> Фильтры и сортировка</li>
                        <li><Icon.Check /> Мобильная адаптация</li>
                        <li><Icon.Check /> Подписка и уведомления</li>
                        <li><Icon.Check /> SEO оптимизация</li>
                        <li><Icon.Check /> Аналитика и мониторинг</li>
                        <li><Icon.Check /> Система рекламы и монетизации</li>
                        <li><Icon.Check /> Техническая поддержка</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import { Link } from "react-router-dom";
import * as Icon from 'react-feather';

export const Services = () => {
  return (
    <>

      <div className="services-area-two pt-80 pb-50 bg-f9f6f6">
        <div className="container">
          <div className="section-title">
            <h2>Зачем реклама бизнесу?</h2>
            <div className="bar"></div>
            <p>Настройка и ведение контекстной рекламы</p>
          </div>

          <div className="row justify-content-center">
            <div className="col-lg-4 col-sm-6">
              <div className="single-services-box">
                <div className="icon">
                  <Icon.Settings />
                </div>
                <h3>
                  <Link to="/service-details">
                  Развиваем присутствие компании в digital-пространстве
                  </Link>
                </h3>
                <p>Помогаем трансформировать ваш бизнес в DIGITAL и адаптировать его под новых клиентов</p>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6">
              <div className="single-services-box">
                <div className="icon">
                  <Icon.ZoomIn />
                </div>
                <h3>
                  <Link to="/service-details">
                  Привлекаем клиентов и создаём новые каналы продаж
                  </Link>
                </h3>
                <p>Решаем бизнес-задачи по привлечению и удержанию клиентов для вашего бизнеса</p>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6">
              <div className="single-services-box">
                <div className="icon">
                  <Icon.Edit3 />
                </div>
                <h3>
                  <Link to="/service-details">
                  Помогаем укреплять имидж вашего бренда
                  </Link>
                </h3>
                <p>Создаём проекты, которые запоминаются и попадают в заголовки СМИ и блогеров</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

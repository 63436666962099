import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './Navbar';
import GoTop from './GoTop';
import MainPage from '../pages/main';
import Contact from '../pages/contact';
import PrivacyPolicy from '../pages/privacy-policy';
import TermCondition from '../pages/term-condition';
import Page404 from '../pages/404';
import Footer from './Footer';
import ScrollToTop from './ScrollToTop';
import SeoDetails from '../pages/seo-details';
import CreatingWebsitesPage from '../pages/creating-websites';
import ComingSoon from '../pages/coming-soon';
import About from '../pages/about';
import Marketing from '../pages/marketing';
import { ModalForm } from './modal/modal';

const Routers = () => (
  <Router>
  <Navbar />
  <ScrollToTop />
  <GoTop scrollStepInPx="100" delayInMs="10.50" />
  <Routes>
    <Route path="/" element={<MainPage />} />
    <Route path="/about" element={<About />} />
    <Route path="/creating-websites" element={<CreatingWebsitesPage />} />
    <Route path="/seo-details" element={<SeoDetails />} />
    <Route path="/marketing" element={<Marketing />} />
    <Route path="/coming-soon" element={<ComingSoon />} />
    <Route path="/contact" element={<Contact />} />
    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
    <Route path="/term-condition" element={<TermCondition />} />
    <Route path="*" element={<Page404 />} />
  </Routes>
  <Footer />
  <ModalForm />
</Router>
);
export default Routers;
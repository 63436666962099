import { Link } from "react-router-dom";
import * as Icon from 'react-feather';
import { useModal } from '../modal/ModalContext';

export const Prices = () => {

  const { handleShow } = useModal();

  const openTabSection = (evt, tabNmae) => {
    let i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabs_item");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }

    tablinks = document.getElementsByTagName("li");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace("current", "");
    }

    document.getElementById(tabNmae).style.display = "block";
    evt.currentTarget.className += "current";
  }


  return (
    <>
      <div className="pricing-area pb-50">
        <div className="container">
          <div className="section-title st-fs-28 mt-3">
            <span className="sub-title">Цены на Seo оптимизацию</span>
            <div className="bar"></div>
            <p> Мы предлагаем гибкие и конкурентоспособные цены на наши услуги. Выберите наиболее подходящий пакет для ваших целей и начните достижение успеха в интернете уже сегодня.</p>
          </div>

          <div className="tab pricing-tab bg-color">
            <ul className="tabs">
              <li
                className="current"
                onClick={(e) => openTabSection(e, 'tab1')}
              >
                Цены за месяц
              </li>

              <li onClick={(e) => openTabSection(e, 'tab2')}>
                Цены за год <i data-feather="calendar"></i>
              </li>
            </ul>

            <div className="tab_content">
              <div id="tab1" className="tabs_item">
                <div className="row justify-content-center">

                  <div className="col-lg-4 col-md-6 col-sm-6">
                    <div className="pricing-box">
                      <div className="pricing-header">
                        <h3>Стартовый пакет</h3>
                        <p>Начните улучшение видимости вашего сайта с нашего стартового пакета SEO-оптимизации</p>
                      </div>

                      <div className="price">
                        от 15 000 руб. <span>/месяц</span>
                      </div>

                      <div className="buy-btn">
                        <button className="btn btn-primary" onClick={() => handleShow("SEO: Стартовый пакет")}>
                          Выбрать
                        </button>
                      </div>

                      <ul className="pricing-features">
                        <li><Icon.Check /> Аудит сайта</li>
                        <li><Icon.Check /> Исследование ключевых слов</li>
                        <li><Icon.Check /> Оптимизация контента</li>
                        <li><Icon.Check /> Техническая оптимизация</li>
                        <li><Icon.Check /> Создание карты сайта и robots.txt</li>
                        <li><Icon.Check /> Оптимизация мета-тегов</li>
                        <li><Icon.Check /> Отчёт и рекомендации</li>
                      </ul>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-6 col-sm-6">
                    <div className="pricing-box">
                      <div className="pricing-header">
                        <h3>Продвинутый пакет</h3>
                        <p>С нашим продвинутым пакетом SEO-оптимизации вы получите всестороннюю оптимизацию сайта</p>
                      </div>

                      <div className="price">
                        от 70 000 руб. <span>/месяц</span>
                      </div>

                      <div className="buy-btn">
                        <button className="btn btn-primary" onClick={() => handleShow("SEO: Продвинутый пакет")}>
                          Выбрать
                        </button>
                      </div>

                      <ul className="pricing-features">
                        <li><Icon.Check /> Аудит сайта и конкурентный анализ</li>
                        <li><Icon.Check /> Исследование ключевых слов</li>
                        <li><Icon.Check /> Оптимизация контента</li>
                        <li><Icon.Check /> Техническая оптимизация</li>
                        <li><Icon.Check /> Создание и оптимизация мета-тегов</li>
                        <li><Icon.Check /> Построение ссылок</li>
                        <li><Icon.Check /> Мониторинг и аналитика</li>
                        <li><Icon.Check /> Социальные сети и контент-маркетинг</li>
                        <li><Icon.Check /> Отчётность и консультации</li>
                      </ul>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-6 col-sm-6">
                    <div className="pricing-box">
                      <div className="pricing-header">
                        <h3>Премиум-пакет</h3>
                        <p>Наш премиум-пакет - это комплексное решение для максимального уровня видимости и эффективности вашего сайта</p>
                      </div>

                      <div className="price">
                        от 200 000 руб. <span>/месяц</span>
                      </div>

                      <div className="buy-btn">
                        <button className="btn btn-primary" onClick={() => handleShow("SEO: Премиум пакет")}>
                          Выбрать
                        </button>
                      </div>

                      <ul className="pricing-features">
                        <li><Icon.Check /> Аудит сайта и конкурентный анализ</li>
                        <li><Icon.Check /> Исследование ключевых слов</li>
                        <li><Icon.Check /> Оптимизация контента</li>
                        <li><Icon.Check /> Техническая оптимизация</li>
                        <li><Icon.Check /> Создание и оптимизация мета-тегов</li>
                        <li><Icon.Check /> Построение авторитетных ссылок</li>
                        <li><Icon.Check /> Мониторинг и аналитика</li>
                        <li><Icon.Check /> Социальные сети и контент-маркетинг</li>
                        <li><Icon.Check /> Оптимизация мобильных приложений</li>
                        <li><Icon.Check /> Индивидуальные консультации</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div id="tab2" className="tabs_item">
                <div className="row justify-content-center">
                  <div className="col-lg-4 col-md-6 col-sm-6">
                    <div className="pricing-box">
                      <div className="pricing-header">
                        <h3>Стартовый пакет</h3>
                        <p>Начните улучшение видимости вашего сайта с нашего стартового пакета SEO-оптимизации</p>
                      </div>

                      <div className="price">
                        от 400 000 руб. <span>/год</span>
                      </div>

                      <div className="buy-btn">
                        <Link to="/contact/" className="btn btn-primary">
                          Выбрать
                        </Link>
                      </div>

                      <ul className="pricing-features">
                        <li><Icon.Check /> Аудит сайта</li>
                        <li><Icon.Check /> Исследование ключевых слов</li>
                        <li><Icon.Check /> Оптимизация контента</li>
                        <li><Icon.Check /> Техническая оптимизация</li>
                        <li><Icon.Check /> Создание карты сайта и robots.txt</li>
                        <li><Icon.Check /> Оптимизация мета-тегов</li>
                        <li><Icon.Check /> Отчёт и рекомендации</li>
                      </ul>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-6 col-sm-6">
                    <div className="pricing-box">
                      <div className="pricing-header">
                        <h3>Продвинутый пакет</h3>
                        <p>С нашим продвинутым пакетом SEO-оптимизации вы получите всестороннюю оптимизацию сайта</p>
                      </div>

                      <div className="price">
                        от 800 000 руб. <span> /год</span>
                      </div>

                      <div className="buy-btn">
                        <Link to="/contact/" className="btn btn-primary">
                          Выбрать
                        </Link>
                      </div>

                      <ul className="pricing-features">
                        <li><Icon.Check /> Аудит сайта и конкурентный анализ</li>
                        <li><Icon.Check /> Исследование ключевых слов</li>
                        <li><Icon.Check /> Оптимизация контента</li>
                        <li><Icon.Check /> Техническая оптимизация</li>
                        <li><Icon.Check /> Создание и оптимизация мета-тегов</li>
                        <li><Icon.Check /> Построение ссылок</li>
                        <li><Icon.Check /> Мониторинг и аналитика</li>
                        <li><Icon.Check /> Социальные сети и контент-маркетинг</li>
                        <li><Icon.Check /> Отчётность и консультации</li>
                      </ul>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-6 col-sm-6">
                    <div className="pricing-box">
                      <div className="pricing-header">
                        <h3>Премиум-пакет</h3>
                        <p>Наш премиум-пакет - это комплексное решение для максимального уровня видимости и эффективности вашего сайта</p>
                      </div>

                      <div className="price">
                        от 2 000 000 руб. <span>/год</span>
                      </div>

                      <div className="buy-btn">
                        <Link to="/contact/" className="btn btn-primary">
                          Выбрать
                        </Link>
                      </div>

                      <ul className="pricing-features">
                        <li><Icon.Check /> Аудит сайта и конкурентный анализ</li>
                        <li><Icon.Check /> Исследование ключевых слов</li>
                        <li><Icon.Check /> Оптимизация контента</li>
                        <li><Icon.Check /> Техническая оптимизация</li>
                        <li><Icon.Check /> Создание и оптимизация мета-тегов</li>
                        <li><Icon.Check /> Построение авторитетных ссылок</li>
                        <li><Icon.Check /> Мониторинг и аналитика</li>
                        <li><Icon.Check /> Социальные сети и контент-маркетинг</li>
                        <li><Icon.Check /> Оптимизация мобильных приложений</li>
                        <li><Icon.Check /> Индивидуальные консультации</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  );
}

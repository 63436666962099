import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from "react-router-dom";

const ComingSoon = () => {
    const navigate = useNavigate();
    const [days, setDays] = useState('');
    const [hours, setHours] = useState('');
    const [minutes, setMinutes] = useState('');
    const [seconds, setSeconds] = useState('');

    useEffect(() => {
        const interval = setInterval(() => {
            commingSoonTime();
        }, 1000);
        return () => clearInterval(interval);
    }, [])

    const commingSoonTime = () => {
        let endTime = new Date("September 23, 2023 17:00:00 PDT");
        let endTimeParse = (Date.parse(endTime)) / 1000;
        let now = new Date();
        let nowParse = (Date.parse(now) / 1000);
        let timeLeft = endTimeParse - nowParse;
        let days = Math.floor(timeLeft / 86400);
        let hours = Math.floor((timeLeft - (days * 86400)) / 3600);
        let minutes = Math.floor((timeLeft - (days * 86400) - (hours * 3600)) / 60);
        let seconds = Math.floor((timeLeft - (days * 86400) - (hours * 3600) - (minutes * 60)));
        if (hours < "10") { hours = "0" + hours; }
        if (minutes < "10") { minutes = "0" + minutes; }
        if (seconds < "10") { seconds = "0" + seconds; }
        setDays(days);
        setHours(hours);
        setMinutes(minutes);
        setSeconds(seconds);
    }

    return (
        <>
            <Helmet>
                <title>Очень скоро</title>
                <meta name="description" content="Эта страница находится в разработке" />
            </Helmet>
            <div className="coming-soon-area">
                <div className="d-table">
                    <div className="d-table-cell">
                        <div className="container">
                            <div className="coming-soon-content">
                                <h1>В разработке</h1>
                                <p>В настоящее время наш сайт проходит плановое техническое обслуживание. Мы скоро вернёмся. Спасибо вам за ваше терпение.</p>

                                <form>
                                    <input type="email" className="email-input" placeholder="Введите email адрес" name="email" required />

                                    <button type="submit" className="submit-btn">
                                        Сообщить
                                    </button>
                                </form>

                                <div id="timer">
                                    <div id="days">
                                        {days} <span>Days</span>
                                    </div>
                                    <div id="hours">
                                        {hours} <span>Hours</span>
                                    </div>
                                    <div id="minutes">
                                        {minutes} <span>Minutes</span>
                                    </div>
                                    <div id="seconds">
                                        {seconds} <span>Seconds</span>
                                    </div>
                                </div>
                                <button onClick={() => navigate(-1)} className="mt-3 btn btn-primary">
									Вернуться
								</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default ComingSoon;
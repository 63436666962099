import './styles/bootstrap.min.css'
import './styles/boxicons.min.css'
import './styles/style.css'
import './styles/responsive.css'
import "swiper/css";
import "swiper/css/bundle";
import Routers from './components/Router';


const App = () => {

  return (
    <Routers />
  );

};

export default App;


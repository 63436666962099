import { useState, useEffect } from "react";
import { useNavigate, Link } from 'react-router-dom';
import * as Icon from "react-feather";


const Navbar = () => {
  const [currentPath, setCurrentPath] = useState("");
  const router = useNavigate();

  useEffect(() => {
    setCurrentPath(router.asPath);
  }, [router]);

  const [menu, setMenu] = useState(true);

  const toggleNavbar = () => {
    setMenu(!menu);
  };

  useEffect(() => {
    let elementId = document.getElementById("header");
    document.addEventListener("scroll", () => {
      if (window.scrollY > 10) {
        elementId.classList.add("is-sticky");
      } else {
        elementId.classList.remove("is-sticky");
      }
    });
  });

  const classOne = menu
    ? "collapse navbar-collapse"
    : "collapse navbar-collapse show";
  const classTwo = menu
    ? "navbar-toggler navbar-toggler-right collapsed"
    : "navbar-toggler navbar-toggler-right";

  return (
    <>
      <header id="header" className="headroom navbar-style-three">
        <div className="startp-nav">
          <div className="container">
            <nav className="navbar navbar-expand-md navbar-light">

              <button
                onClick={toggleNavbar}
                className={classTwo}
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="icon-bar top-bar"></span>
                <span className="icon-bar middle-bar"></span>
                <span className="icon-bar bottom-bar"></span>
              </button>

              <div className={classOne} id="navbarSupportedContent">
                <ul className="navbar-nav m-auto">
                  <li className="nav-item">
                    <Link
                      to="/"
                      onClick={toggleNavbar}
                      className={`nav-link ${currentPath === "/" && "active"
                        }`}
                    >
                      Главная
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/coming-soon/"
                      onClick={toggleNavbar}
                      className={`disabled-link nav-link ${currentPath === "/coming-soon/" && "active"
                        }`}
                    >
                      Веб-разработка и дизайн
                      <Icon.ChevronDown />
                    </Link>
                    <ul className="dropdown-menu">
                      <li className="nav-item">
                        <Link
                          to="/creating-websites/"
                          onClick={toggleNavbar}
                          className={`nav-link ${currentPath === "/creating-websites/" && "active"
                            }`}
                        >
                          Создание веб-сайтов
                        </Link>
                      </li>
                      {/* <li className="nav-item">
                        <Link
                          to="/coming-soon/"
                          onClick={toggleNavbar}
                          className={`disabled-link nav-link ${currentPath === "/coming-soon/" && "active"
                            }`}
                        >
                          Разработка мобильных приложений
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/coming-soon/"
                          onClick={toggleNavbar}
                          className={`disabled-link nav-link ${currentPath === "/coming-soon/" && "active"
                            }`}
                        >
                          UX/UI дизайн
                        </Link>
                      </li> */}
                    </ul>
                  </li>

                  <li className="nav-item">
                    <Link
                      to="/coming-soon/"
                      onClick={toggleNavbar}
                      className={`disabled-link nav-link ${currentPath === "//coming-soon/" && "active"
                        }`}
                    >
                      Продвижение
                      <Icon.ChevronDown />
                    </Link>
                    <ul className="dropdown-menu">
                      <li className="nav-item">
                        <Link
                          to="/seo-details/"
                          onClick={toggleNavbar}
                          className={`nav-link ${currentPath === "/seo-details/" && "active"
                            }`}
                        >
                          SEO-оптимизация
                        </Link>
                      </li>
                      <li className="nav-item">
                        <a
                          href="https://avitologs.digital-corporation.ru"
                          target="_blank"
                          onClick={toggleNavbar}
                          className={`nav-link`} rel="noreferrer"
                        >
                          Продвижение на авито
                        </a>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/marketing/"
                          onClick={toggleNavbar}
                          className={`nav-link ${currentPath === "/marketing/" && "active"
                            }`}
                        >
                          Реклама
                        </Link>
                      </li>
                    </ul>
                  </li>
                  {/* <li className="nav-item">
                    <Link
                      to="/coming-soon/"
                      onClick={toggleNavbar}
                      className={`disabled-link nav-link ${currentPath === "/coming-soon/" && "active"
                        }`}
                    >
                      Блокчейн
                    </Link>
                  </li> */}
                  {/* <li className="nav-item">
                    <Link
                      to="/coming-soon/"
                      onClick={toggleNavbar}
                      className={`disabled-link nav-link ${currentPath === "//coming-soon/" && "active"
                        }`}
                    >
                      Социальные медиа
                      <Icon.ChevronDown />
                    </Link>
                    <ul className="dropdown-menu">
                      <li className="nav-item">
                        <Link
                          to="/coming-soon/"
                          onClick={toggleNavbar}
                          className={`disabled-link nav-link ${currentPath === "/coming-soon/" && "active"
                            }`}
                        >
                          Управление аккаунтами в социальных сетях
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/coming-soon/"
                          onClick={toggleNavbar}
                          className={`disabled-link nav-link ${currentPath === "/coming-soon/" && "active"
                            }`}
                        >
                          Разработка стратегии социальных медиа
                        </Link>
                      </li>

                    </ul>
                  </li> */}
                  {/* <li className="nav-item">
                    <Link
                      to="/coming-soon/"
                      onClick={toggleNavbar}
                      className={`disabled-link nav-link ${currentPath === "//coming-soon/" && "active"
                        }`}
                    >
                      Реклама
                      <Icon.ChevronDown />
                    </Link>
                    <ul className="dropdown-menu">
                      <li className="nav-item">
                        <Link
                          to="/coming-soon/"
                          onClick={toggleNavbar}
                          className={`disabled-link  nav-link ${currentPath === "/coming-soon/" && "active"
                            }`}
                        >
                          Управление рекламными кампаниями в поисковых системах
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/coming-soon/"
                          onClick={toggleNavbar}
                          className={`disabled-link nav-link ${currentPath === "/coming-soon/" && "active"
                            }`}
                        >
                          Оптимизация рекламных бюджетов
                        </Link>
                      </li>

                    </ul>
                  </li> */}
                  <li className="nav-item">
                    <Link
                      to="/contact/"
                      onClick={toggleNavbar}
                      className={`nav-link ${currentPath === "/contact/" && "active"
                        }`}
                    >
                      Наши контакты
                    </Link>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </header>
    </>
  );
};

export default Navbar;

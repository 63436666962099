import { Helmet } from 'react-helmet';
import Navbar from "../components/Navbar";
import PageBanner from '../components/PageBanner';
import { Services } from '../components/website-page/services';
import { Prices } from "../components/website-page/prices";
import { FAQ } from '../components/Faq';
import { questions, answers } from '../components/website-page/questions';

const CreatingWebsitesPage = () => {
  return (
    <>
      <Helmet>
        <title>Создание сайтов</title>
        <meta name="description" content="Наши эксперты в веб-разработке и дизайне работают над тем, чтобы создать функциональные, креативные и пользовательские веб-сайты, которые не только привлекают внимание, но и конвертируют посетителей в клиентов." />
      </Helmet>
      < Navbar />
      < PageBanner pageTitle="Создание веб-сайтов" />
      < Services />
      < Prices />
      < FAQ questions={questions} answers={answers} />
    </>
  )
}

export default CreatingWebsitesPage;
import { Helmet } from 'react-helmet';
import Navbar from "../components/Navbar";
import PageBanner from '../components/PageBanner';
import { Services } from '../components/marketing-page/services';
import { Prices } from "../components/marketing-page/prices";


const Marketing = () => {
  return (
    <>
      <Helmet>
        <title>Ведение рекламы вашнго бизнеса</title>
        <meta name="description" content="Без рекламы никак" />
      </Helmet>
      < Navbar />
      < PageBanner pageTitle="Помогаем развивать ваш бизнес" />
      < Services />
      < Prices />
    </>
  )
}

export default Marketing
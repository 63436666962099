import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

export const FAQ = ({ questions, answers }) => {
  return (
    <div className="faq-area ptb-80">
      <div className="container">
        <div className="faq-accordion">
          <div className="section-title">
            <span className="sub-title">Вопросы и Ответы</span>
            <div className="bar"></div>
          </div>
          <Accordion preExpanded={['a']}>
            {questions.map((question, index) => (
              <AccordionItem key={index} uuid={`faq-${index}`}>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <span>{question}</span>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>{answers[index]}</p>
                </AccordionItemPanel>
              </AccordionItem>
            ))}
          </Accordion>
        </div>
      </div>
    </div>
  );
};

import { useModal } from './ModalContext';
import { useState, useEffect } from 'react';
import validator from 'validator';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import axios from "axios";

export const ModalForm = () => {
  const { show, handleClose, tariff } = useModal();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isChecked, setIsChecked] = useState(true);

  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({
    phone: null,
  });

  useEffect(() => {
    setFormData({
      ...formData,
      type: tariff,
    });
  }, [tariff]);

  const validatePhone = (value) => {
    if (!value) {
      return 'Это поле обязательно для заполнения.';
    } else if (!validator.isMobilePhone(value, 'any', { strictMode: false })) {
      return 'Введите корректный номер телефона.';
    }
    return null;
  };

  const handleChange = (e) => {
    const { name, value, type } = e.target;

    if (type === 'radio') {
      setFormData({
        ...formData,
        contactMethod: value,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });

      if (name === 'phone') {
        const phoneError = validatePhone(value);
        setErrors({
          ...errors,
          phone: phoneError,
        });
      }
    }
  };


  const handleSend = async () => {
    console.log(formData)
    try {
      const url = '/sendEmail.php';
      const response = await axios.post(url, formData);
      console.log(response);
      window.ym(93711172,'reachGoal','sendForm')
      setIsSubmitted(true);
      setTimeout(() => {
        handleClose();
      }, 5000);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered >
        <Modal.Header closeButton>
          <Modal.Title>{tariff}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!isSubmitted ? (
            <Form>
              <Form.Group controlId="phone">
                <Form.Control
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Введите ваше имя"
                  required
                />
              </Form.Group>

              <Form.Label className="mt-3">Предпочитаемый тип связи</Form.Label>
              <div className="m-1 d-flex justify-content-evenly">
                <Form.Check inline label='По телефону' name="contactMethod" type='radio' value='По телефону' onChange={handleChange} id='telephone' />
                <Form.Check inline label='В Telegram' name="contactMethod" type='radio' value='В Telegram' onChange={handleChange} id='telegram' />
                <Form.Check inline label='В Whatsapp' name="contactMethod" type='radio' value='В Whatsapp' onChange={handleChange} id='whatsapp' />
              </div>

              <Form.Group controlId="phone" className="mt-3">
                <Form.Control
                  type="text"
                  name="phone"
                  placeholder="Введите ваш номер телефона"
                  value={formData.phone}
                  onChange={handleChange}
                  onBlur={handleChange}
                  isInvalid={!!errors.phone}
                />
                <Form.Control.Feedback type="invalid">{errors.phone}</Form.Control.Feedback>
              </Form.Group>
              <Form.Check
                type="switch"
                className="mt-3"
                id="1"
                onChange={() => setIsChecked(!isChecked)}
                label="Нажимая это, вы соглашаетесь с нашими правилами и политикой приватности."
              />

            </Form>
          ) : (
            <div>
              <p>Ваше сообщение было успешно отправлено и скоро мы ответим вам.</p>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          {!isSubmitted &&
            <Button variant="secondary" onClick={handleSend} disabled={isChecked || !formData.phone}>
              Отправить заявку
            </Button>
          }
        </Modal.Footer>
      </Modal>
    </>
  );
}
import { Helmet } from 'react-helmet';
import Navbar from "../components/Navbar";

import FunFactsArea from "../components/FunFactsArea";
import PageBanner from '../components/PageBanner';

const About = () => {
    return (
        <>
            <Helmet>
                <title>О нас</title>
                <meta name="description" content="Мы разрабатываем креативные и функциональные веб-сайты и мобильные приложения, а также улучшаем существующие." />
            </Helmet>
            < Navbar />
            < PageBanner pageTitle="О нас" />
            <div className="about-area ptb-80">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="ml-about-img">
                                <img src="/images/undraw_team_collaboration.svg" alt="team" />
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="ml-about-content">
                                <span className="sub-title">О нас</span>
                                <h2>Что мы делаем?</h2>
                                <div className="bar"></div>

                                <p>Разрабатываем креативные и функциональные веб-сайты и мобильные приложения, а также улучшаем существующие.</p>
                                <p>Создаем и внедряем эффективные стратегии SEO, чтобы улучшить видимость вашего бизнеса в поисковых системах.</p>
                                <p>Помогаем создавать и продвигать качественный контент, чтобы привлечь и удержать вашу аудиторию.</p>
                                <p>Предоставляем консультации и обучение в сфере цифрового маркетинга и веб-разработки.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="about-inner-area">
                        <div className="row justify-content-center">
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="about-text">
                                    <h3>Наша история</h3>
                                    <p>Начиная с 2015 года, мы постоянно росли и развивались, содействуя успеху наших клиентов и оставаясь на передовой в цифровой индустрии.</p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="about-text">
                                    <h3>Наша миссия</h3>
                                    <p>Мы стремимся быть партнером №1 для наших клиентов, предоставляя высококачественные цифровые решения для их успеха в онлайн-мире.</p>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="about-text">
                                    <h3>Кто мы?</h3>
                                    <p>Мы — опытная команда профессионалов в области цифрового маркетинга и веб-разработки.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            < FunFactsArea />
        </>
    )
}

export default About;
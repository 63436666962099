import * as Icon from "react-feather";
import { Link } from "react-router-dom";


const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <>
      <footer className="footer-area bg-f7fafd">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="single-footer-widget">
                <div className="logo">
                    <img src="/images/logo.png" alt="logo" />
                </div>
                <p>
                Наша команда профессионалов, предоставляет комплексные решения веб-разработки, дизайна, маркетинга и брендинга.
                </p>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="single-footer-widget pl-5">
                <h3>Компания</h3>
                <ul className="list">
                  <li>
                    <Link to="/about">О нас</Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="single-footer-widget">
                <h3>Поддержка</h3>
                <ul className="list">
                  <li>
                    <Link to="/privacy-policy">Политика конфиденциальности</Link>
                  </li>
                  <li>
                    <Link to="/term-condition">Условия использования</Link>
                  </li>
                  <li>
                    <Link to="/contact">Напишите нам</Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="single-footer-widget">
                <h3>Контакты</h3>

                <ul className="footer-contact-info">
                  <li>
                    <Icon.Mail />
                    Email:{" "}
                    <a href="mailto:clients@digital-corporation.ru">clients@digital-corporation.ru</a>
                  </li>
                  <li>
                    <Icon.PhoneCall />
                    Телефон: <a href="tel:+79934638694">+7(993)463-86-94</a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-12 col-md-12">
              <div className="copyright-area">
                <p>
                  &copy; {currentYear}, Общество с ограниченной ответственностью "Цифровая корпорация", ИНН: 6317163559.
                  Все права защищены.
                </p>
              </div>
            </div>
          </div>
        </div>

        <img src="/images/map.png" className="map" alt="map" />

        {/* Shape Images */}
        <div className="shape1">
          <img src="/images/shape1.png" alt="shape" />
        </div>
        <div className="shape8 rotateme">
          <img src="/images/shape2.svg" alt="shape" />
        </div>
      </footer>
    </>
  );
};

export default Footer;

import { Helmet } from 'react-helmet';
import PageBanner from '../components/PageBanner';
import ContactInfo from '../components/ContactInfo';
import ContactForm from '../components/ContactForm';

const Contact = () => {
    return (
        <>
            <Helmet>
                <title>Наши контакты</title>
                <meta name="description" content="По этим контактам вы сможете с нами связаться" />
            </Helmet>
            < PageBanner pageTitle="Наши контакты" />
            < ContactInfo />
            < ContactForm />
        </>
    )
}

export default Contact;
export const questions = [
  'Что такое создание сайтов?',
  'Зачем мне нужен сайт для моего бизнеса?',
  'Какой стоимости создание сайта?',
  'Сколько времени занимает создание сайта?',
  'Какие этапы включает в себя процесс создания сайта?',
];

export const answers = [
  'Создание сайтов - это процесс разработки и настройки веб-ресурса, который может использоваться для различных целей, таких как бизнес, личный блог, интернет-магазин и другие.',
  'Сайт может значительно увеличить видимость вашего бизнеса в интернете, привлечь новых клиентов, предоставить информацию о ваших товарах или услугах и улучшить взаимодействие с клиентами.',
  'Стоимость создания сайта может сильно варьироваться в зависимости от различных факторов, таких как сложность дизайна, функциональные требования, объем контента и другие. Цена может начинаться от нескольких сотен долларов и выше.',
  'Время, необходимое для создания сайта, также зависит от его сложности и требований. Простой сайт может быть разработан в течение нескольких недель, в то время как сложные проекты могут занять несколько месяцев.',
  'Процесс создания сайта обычно включает в себя следующие этапы: планирование, дизайн, разработка, тестирование и запуск. На каждом этапе работают специалисты с разными навыками, чтобы создать качественный сайт.',
];

import * as Icon from 'react-feather';

const ContactInfo = () => {
    return (
        <>
            <div className="contact-info-area ptb-80">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6 col-md-6 col-sm-6">
                            <div className="contact-info-box">
                                <a href="mailto:clients@digital-corporation.ru">
                                    <div className="icon">
                                        <Icon.Mail />
                                    </div>
                                </a>
                                <h3>Наша почта</h3>
                                <p><a href="mailto:clients@digital-corporation.ru">clients@digital-corporation.ru</a></p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6">
                            <div className="contact-info-box">
                                <a href="tel:+79934638694">
                                    <div className="icon">
                                        <Icon.PhoneCall />
                                    </div>
                                </a>
                                <h3>Позвоните нам</h3>
                                <p><a href="tel:+79934638694">+7(993)463-86-94</a></p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 mt-4">
                            <div className="contact-info-box">
                                <a href="https://t.me/digital_corporacy">
                                    <div className="icon">
                                        <Icon.Send />
                                    </div>
                                </a>
                                <h3>Напишите нам в Telegram</h3>
                                <p><a href="https://t.me/digital_corporacy">@digital_corporacy</a></p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 mt-4">
                            <div className="contact-info-box">
                                <a href="https://wa.me/+79934638694">
                                    <div className="icon">
                                        <Icon.MessageCircle />
                                    </div>
                                </a>
                                <h3>Напишите нам в WhatsApp</h3>
                                <p><a href="https://wa.me/+79934638694">+7(993)463-86-94</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ContactInfo;